import React, {Fragment} from "react";
import styles from './Footer.module.css';
import {useTranslation} from "react-i18next";
import {NavLink, useLocation} from "react-router-dom";

export default function Footer() {
  const {t} = useTranslation();
  const path = useLocation().pathname;
  // const changeLanguageLink = path === "/" ? "/de" : path === "/de" ? "/" : path.startsWith("/de") ? path.substring(3) : "/de" + path;
  return (
    <footer className={styles.footer}>
      <span className={styles.links}>
        {/*<NavLink exact to={changeLanguageLink}>{t("Lang") === "de" ? "English" : "Deutsch"}</NavLink>*/}
        <a href={"https://" + (t("Lang") === "de" ? "learnbox" : "lernbox") + ".app" + path}>{t("Lang") === "de" ? "English" : "Deutsch"}</a>
        <span className={styles.separator}>|</span>
        <a href="mailto:contact@maxhaertwig.com">{t("Contact")}</a>
        <span className={styles.separator}>|</span>
        <a href={t("DeveloperWebsiteLink")}>{t("DeveloperWebsite")}</a>
        {!path.endsWith("privacy-policy") &&
        <Fragment>
          <span className={styles.separator}>|</span>
          <NavLink exact to="privacy-policy">{t("PrivacyPolicy")}</NavLink>
          {/*<NavLink exact to={(t("Lang") === "de" ? "de/" : "") + "privacy-policy"}>{t("PrivacyPolicy")}</NavLink>*/}
        </Fragment>
        }
      </span>
      <span className={styles.copyrightNotice}>{t("CopyrightNotice")}</span>
    </footer>
  );
}
