import ReadableContent from './components/ReadableContent';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Footer from "./sections/Footer";
import HomePage from "./pages/HomePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import NotFoundPage from "./pages/NotFoundPage";
import {Helmet} from "react-helmet";
import React from "react";
import {useTranslation} from "react-i18next";

export default function App() {
  const {t} = useTranslation();
  return (
    <BrowserRouter>
      {/*<SetLanguageComponent/>*/}
      <Helmet>
        <html lang={t("Lang")} />
        <title>{t("Learnbox")}</title>
      </Helmet>
      <ReadableContent>
        <Switch>
          {/*<Redirect exact from="/en" to="/" />*/}
          {/*<Route exact path="/(de)?">*/}
          <Route exact path="/">
            <HomePage/>
          </Route>
          {/*<Route exact path="/(de)?/privacy-policy">*/}
          <Route exact path="/privacy-policy">
            <PrivacyPolicyPage/>
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
        <Footer/>
      </ReadableContent>
    </BrowserRouter>
  );
}
