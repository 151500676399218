import React from 'react';
import PropTypes from 'prop-types';
import styles from './AdditionalFeature.module.css';
import {useTranslation} from "react-i18next";

export default function AdditionalFeature({icon, feature}) {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      <img className={styles.icon} src={icon} alt={t(feature) + "Icon"} />
      <h3 className={styles.title}>{t(feature)}</h3>
      <p className={styles.text}>{t(feature + "Text")}</p>
    </div>
  );
}

AdditionalFeature.propTypes = {
  icon: PropTypes.any.isRequired,
  feature: PropTypes.string.isRequired
};
