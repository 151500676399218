import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReviewCard.module.css';
import {useTranslation} from "react-i18next";

export default function ReviewCard({review, fading}) {
  const {t} = useTranslation();
  const date = new Date(review.date);
  return (
    <div className={styles.card + (fading ? ` ${styles.faded}` : "")}>
      <div>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{review.title}</span>
          <span className={styles.stars}>{'\u2605'.repeat(review.stars)}</span>
        </div>
        <p className={styles.text}>{review.review}</p>
      </div>
      <div className={styles.metadataContainer}>
        <span className={styles.metadata}>{review.author}, {date.toLocaleDateString(t("Lang") === "de" ? "de-DE" : "en-US")}</span>
      </div>
    </div>
  );
}

ReviewCard.propTypes = {
  review: PropTypes.object.isRequired,
  fading: PropTypes.bool
};
