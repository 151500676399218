import PropTypes from "prop-types";
import React from "react";
import styles from "./LinkButton.module.css";

export default function LinkButton({text, url}) {
  return (
    <a className={styles.button} href={url}>
      {text}
    </a>
  );
}

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};
