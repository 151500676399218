import React from 'react';
import PropTypes from 'prop-types';
import styles from './FeatureSection.module.css';
import Heading from "../components/Heading";
import {useTranslation} from "react-i18next";

export default function FeatureSection({screenshot, name, iPad}) {
  const {t} = useTranslation();
  return (
    <div className={styles.section}>
      <div className={iPad === true ? styles.iPadScreenshotContainer : styles.screenshotContainer}>
        <img className={iPad === true ? styles.iPadScreenshot : styles.screenshot} src={screenshot} alt={t(name + "Alt")}/>
        <div className={styles.gradient}/>
      </div>
      <div className={styles.textContainer}>
        <Heading text={t(name)} />
        <p className={styles.text}>{t(name + "Text")}</p>
      </div>
    </div>
  );
}

FeatureSection.propTypes = {
  screenshot: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  iPad: PropTypes.bool
};
