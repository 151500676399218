import React from 'react';
import styles from './IntroductionSection.module.css';
import {useTranslation} from "react-i18next";

export default function IntroductionSection() {
  const {t} = useTranslation();
  return (
    <div className={styles.section}>
      <p className={styles.introduction}>{t("IntroductionText")}</p>
    </div>
  );
}
