export const reviewsEn = [
    {
        "author": "vdubovitsky",
        "title": "Great app!",
        "review": "Notifications, pretty convenient cards management, and just enough free functionality to use it before considering a one-time purchase to unlock the only extras you needed. Highly recommend!",
        "stars": "5",
        "date": "2020-07-01T22:20:19"
    },
    {
        "author": "\u0420\u0435\u043d\u0438 \u0418\u043b\u0438\u0435\u0432\u0430",
        "title": "Love the app!",
        "review": "I love that app and that is what I was looking for. This is my second review one year after using it and I still love it because it helped me a lot with my Greek vocabulary.",
        "stars": "5",
        "date": "2020-06-14T06:41:50"
    },
    {
        "author": "Almanacgree",
        "title": "Better than Anki",
        "review": "It\u2019s way more better than anki. Awesome design. I\u2019m looking forward to updates",
        "stars": "5",
        "date": "2019-11-09T14:45:38"
    },
    {
        "author": "NimJang",
        "title": "Effective and simple",
        "review": "Simple learning app that gives you open hands how to organize your cards. It also has three level of learning what makes it a very effective for you to learn.",
        "stars": "5",
        "date": "2019-06-25T02:29:08"
    },
    {
        "author": "Kamik423",
        "title": "Support keyboard shortcuts and has great UI",
        "review": "I tried a bunch of big name flash card apps before this one. But this one is the first one to let me input a whole bunch of cards using keyboard shortcuts on iPad Pro. And its UI is not covered by the keyboard like that of others. I don\u2018t have to create an account and sign up to a newsletter. And the UI is extremely straightforward.\nI love it!",
        "stars": "5",
        "date": "2019-05-01T13:43:49"
    },
    {
        "author": "Fatboy1882",
        "title": "Worth every Cent",
        "review": "Purchase was best choice to increase my language skills.",
        "stars": "5",
        "date": "2019-02-02T19:42:05"
    },
    {
        "author": "Gedstet",
        "title": "Great Trainer",
        "review": "This App is just great for setting up gathering words and grammar and setting up tests so that the information is learnt",
        "stars": "5",
        "date": "2018-10-06T21:18:19"
    },
    {
        "author": "Maxim Sorokin",
        "title": "The best in this class of APPs.",
        "review": "Highly recommended",
        "stars": "5",
        "date": "2018-08-10T09:30:33"
    },
    {
        "author": "motine!",
        "title": "Does what it should.",
        "review": "Good work. It does exactly what I expect from a vocabulary trainer. Thanks.",
        "stars": "5",
        "date": "2018-03-11T15:13:42"
    },
    {
        "author": "Sjlllk234",
        "title": "Top App! Highly recommendable!",
        "review": "Really good application to practice vocabulary.\nMinor things could be optimized, like exporting and importing lists and cards. But great App!",
        "stars": "5",
        "date": "2015-02-24T07:15:00"
    },
    {
        "author": "fridayschild78",
        "title": "Ms",
        "review": "Best app ever for learning vocab! My grades have improved significantly since I started using Learnbox.",
        "stars": "5",
        "date": "2014-11-07T02:39:02"
    },
    {
        "author": "Jerichobapes",
        "title": "Try it",
        "review": "Scientifically proven",
        "stars": "5",
        "date": "2013-01-07T05:35:00"
    }
];
