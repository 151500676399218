import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Heading from "../components/Heading";
import styles from "./PrivacyPolicyPage.module.css";
import {NavLink} from "react-router-dom";

export default function PrivacyPolicyPage() {
  const {t} = useTranslation();
  const title = t("Learnbox") + " - " + t("PrivacyPolicy");
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <NavLink className={styles.homeButton} exact to="/">
        &#10092;{" " + t("Home")}
      </NavLink>
      <article>
        <Heading text={title} />
        {t("Lang") !== "de" &&
        <div className={styles.section}>
          <p>Please note: The privacy policy is currently available only in German as German law applies. For questions or inquiries, feel free to <a href="mailto:contact@maxhaertwig.com">contact me</a>.</p>
        </div>
        }
        <div className={styles.section}>
          <p className={styles.heading}>1. Allgemeines</p>
          <p>Die App "Lernbox" ist ein Angebot von Max Härtwig, nachfolgend MH genannt. MH nimmt den Datenschutz ernst. Die Nutzung Ihrer Daten erfolgt im Rahmen der gesetzlichen Vorschriften. Über diese können Sie sich unter <a href="https://www.datenschutz.de">www.datenschutz.de</a> umfassend informieren. Wenn Sie die App benutzen, stimmen Sie den Datenschutzbestimmungen zu.</p>
        </div>
        <div className={styles.section}>
          <p className={styles.heading}>2. Personenbezogene Daten</p>
          <p>Personenbezogene Daten im Sinne dieser Datenschutzbestimmungen sind Einzelangaben über Ihre persönlichen und sachlichen Verhältnisse. Dies ist bei Lernbox nur die E-Mail Adresse, die Sie bei der Kontaktaufnahme nutzen. Andere personenbezogene Daten werden nicht erhoben. Diese wird nur verwendet, um eine Antwort zu senden und wird nicht mit Dritten geteilt.</p>
        </div>
        <div className={styles.section}>
          <p className={styles.heading}>3. Datenverarbeitungen der App</p>
          <p>3.1 Beim Herunterladen der App werden die dafür notwendigen Informationen an den App Store übertragen. Auf diese Datenerhebung hat MH jedoch keinen Einfluss und ist nicht dafür verantwortlich.</p>
          <p>3.2 Beim Hinzufügen von Bildern werden Sie um die Erlaubnis zur Nutzung Ihrer Kamera sowie Ihrer Fotos gebeten. Wenn Sie dies ablehnen, nutzt Lernbox diese Daten nicht. Eventuell können Sie in diesem Fall nicht alle Funktionen der App nutzen. Sie können die Erlaubnis später in den Einstellungen des Betriebssystems erteilen oder widerrufen. Wenn Sie den Zugriff auf diese Daten gestatten, wird die App nur auf sie zugreifen, um ihre Funktion zu erbringen.</p>
          <p>3.3 Die iCloud Synchronisation nutzt Apple's iCloud Dienst und bietet MH weder Zugriff noch Einsicht auf die Daten.</p>
          <p>3.4 Bei der Nutzung der automatischen Übersetzungsfunktion für Vokabeln kann es vorkommen, dass das eingegebene Wort bzw. der eingegebene Satz an Google Translate übertragen wird, um ihn zu übersetzen. Außerdem können diese Daten jederzeit von MH eingesehen werden. Dabei wird nur die zu übersetzende Zeichenkette übertragen und kann weder einem Gerät noch einem Nutzer zugewiesen, noch zurückverfolgt werden. Weitere Informationen können in der <a href="https://cloud.google.com/translate/faq#data_confidentiality">Datenschutzerklärung von Google Translate</a> gefunden werden.</p>
          <p>3.5 Um die derzeitige und zukünftige Nutzererfahrung zu verbessern nutzt diese App Firebase Analytics und Crashlytics. Es werden anonyme Informationen über die Nutzung der App und Daten bezogen auf die Performance und Stabilität erhoben. Dabei werden Daten wie App Version, iOS Version, Gerätetyp und die Einstellungen der App übertragen. Weitere Informationen können in den <a href="https://firebase.google.com/support/privacy/">Datenschutzbestimmungen von Firebase</a> gefunden werden.</p>
        </div>
        <div className={styles.section}>
          <p className={styles.heading}>4. Abrufbarkeit der Datenschutzerklärung</p>
          <p>Sie können diese Datenschutzerklärung jederzeit in der App unter Einstellungen, Über Lernbox, Datenschutzerklärung einsehen.</p>
        </div>
        <div className={styles.section}>
          <p className={styles.heading}>5. Kontakt</p>
          <p>Bei Fragen oder Auskunftswünschen können Sie MH jederzeit unter <a href="mailto:contact@maxhaertwig.com">contact@maxhaertwig.com</a> kontaktieren.</p>
        </div>
        <p>{t("PrivacyPolicyUpdated")}</p>
      </article>
    </Fragment>
  );
}
