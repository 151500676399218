import PropTypes from "prop-types";
import React from "react";
import styles from "./Heading.module.css";

export default function Heading({text}) {
  return (
    <h2 className={styles.heading}>{text}</h2>
  );
}

Heading.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired
};
