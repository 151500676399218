import React from 'react';
import styles from './AdditionalFeaturesSection.module.css';
import AdditionalFeature from "../components/AdditionalFeature";
import appleWatchIcon from '../res/features/apple-watch.png';
import archiveIcon from '../res/features/archive.png';
import darkModeIcon from '../res/features/dark-mode.png';
import iCloudSyncIcon from '../res/features/icloud-sync.png';
import imagesIcon from '../res/features/images.png';
import printingIcon from '../res/features/printing.png';

export default function AdditionalFeaturesSection() {
  const features = [
    ["Images", imagesIcon],
    ["Archive", archiveIcon],
    ["Printing", printingIcon],
    ["AppleWatch", appleWatchIcon],
    ["DarkMode", darkModeIcon],
    ["iCloudSync", iCloudSyncIcon]
  ];
  return (
    <div className={styles.container}>
      {features.map(f => <AdditionalFeature key={f[0]} icon={f[1]} feature={f[0]} />)}
    </div>
  );
}
