import React from 'react';
import styles from './HeroSection.module.css';
import learnboxLogo from '../res/learnbox-icon-rounded.png';
import appStoreBanner from '../res/app-store-en.svg';
import iPhoneMain from '../res/screenshots/iphone-main.png';
import iPadMain from '../res/screenshots/ipad-main.png';
import {useTranslation} from "react-i18next";

export default function HeroSection() {
  const {t} = useTranslation();
  return (
    <div className={styles.hero}>
      <div className={styles.leftSide}>
        <img className={styles.logo} src={learnboxLogo} alt={t("LearnboxLogo")}/>
        <h1 className={styles.title}>{t("Learnbox")}</h1>
        <p className={styles.subtitle}>{t("LearnboxSubtitle")}</p>
        <a className={styles.appStoreBanner}
           href={t("AppStoreLink")}>
          <img src={appStoreBanner} alt={t("DownloadOnAppStore")}/>
        </a>
      </div>
      <img className={styles.iPhoneScreenshot} src={iPhoneMain} alt={t("iPhoneScreenshotCard")}/>
      <img className={styles.iPadScreenshot} src={iPadMain} alt={t("iPadScreenshotVocabulary")}/>
    </div>
  );
}