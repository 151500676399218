import React, {Component} from 'react';
import styles from './ReviewsSection.module.css';
import ReviewCard from "../components/ReviewCard";
import Heading from "../components/Heading";
import LinkButton from "../components/LinkButton";
import {withTranslation} from "react-i18next";
import {reviewsDe} from "../res/reviews/reviews_de";
import {reviewsEn} from "../res/reviews/reviews_en";

class ReviewsSectionClass extends Component {
  constructor(props) {
    super(props);
    const reviews = props.t("Lang") === "de" ? reviewsDe : reviewsEn;
    this.state = {
      reviews: reviews,
      indices: this.generateStartIndices(reviews.length, 6),
      lastIndices: [0, 1, 2],
      fadingIndex: null,
      intervalId: null
    };
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      let replaceIndex = this.random(6);
      while (this.state.lastIndices.includes(replaceIndex)) {
        replaceIndex = this.random(6);
      }

      let newReviewIndex = this.random(this.state.reviews.length);
      while (this.state.indices.includes(newReviewIndex)) {
        newReviewIndex = this.random(this.state.reviews.length);
      }

      let newIndices = [...this.state.indices];
      newIndices[replaceIndex] = newReviewIndex;

      setTimeout(() => {
        this.setState({indices: newIndices});
        setTimeout(() => {
          this.setState({fadingIndex: null});
        }, 1);
      }, 1000);

      this.setState(prevState => ({
        lastIndices: [prevState.lastIndices[1], prevState.lastIndices[2], replaceIndex],
        fadingIndex: replaceIndex
      }));
    }, 5000);
    this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  random(n) {
    return Math.floor(Math.random() * n);
  }

  generateStartIndices(n, c) {
    const indices = [this.random(n)];
    while (indices.length < c) {
      const index = this.random(n);
      if (!indices.includes(index)) {
        indices.push(index);
      }
    }
    return indices;
  }

  render() {
    const {t} = this.props;
    return (
      <div className={styles.container}>
        <Heading text={t("Reviews")}/>
        <div className={styles.reviewsContainer}>
          {this.state.indices.map((r, i) => <ReviewCard key={this.state.reviews[r].author} review={this.state.reviews[r]} fading={i === this.state.fadingIndex}/>)}
        </div>
        <LinkButton text={t("WriteReview")} url={t("WriteReviewLink")}/>
      </div>
    );
  }
}

const ReviewsSection = withTranslation()(ReviewsSectionClass)
export default ReviewsSection;
