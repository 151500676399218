import React, {Fragment} from 'react';
import HeroSection from "../sections/HeroSection";
import IntroductionSection from "../sections/IntroductionSection";
import FeatureSection from "../sections/FeatureSection";
import AdditionalFeaturesSection from "../sections/AdditionalFeaturesSection";
import ReviewsSection from "../sections/ReviewsSection";
import iPhoneAndiPad from '../res/screenshots/iphone-and-ipad.png';
import iPhoneLevels from '../res/screenshots/iphone-levels.png';
import iPhonePractice from '../res/screenshots/iphone-practice.png';
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

export default function HomePage() {
  const {t} = useTranslation();
  const features = [
    [iPhoneLevels, "SystemFeature", false],
    [iPhonePractice, "PracticeFeature", false],
    [iPhoneAndiPad, "AllDevicesFeature", true]
  ];
  return (
    <Fragment>
      <Helmet>
        <title>{t("Learnbox")}</title>
      </Helmet>
      <HeroSection />
      <IntroductionSection />
      {features.map(f => <FeatureSection key={f[1]} screenshot={f[0]} name={f[1]} iPad={f[2]}/>)}
      <AdditionalFeaturesSection />
      <ReviewsSection />
    </Fragment>
  );
}
