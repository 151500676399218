export const reviewsDe = [
    {
        "author": "MG1164",
        "title": "Spitzenlernbox!",
        "review": "Nicht nur vom Design (bitte niemals \u00e4ndern!) aber auch von der Funktionalit\u00e4t her eine unfassbar gute Applikation.",
        "stars": "5",
        "date": "2020-12-15T08:17:07"
    },
    {
        "author": "f.fmusic",
        "title": "Beste Lernapp die ich kenne!",
        "review": "Diese App ist einfach nur toll. Super n\u00fctzlich, super gut aufgebaut und ein super Design. \nDank der tollen Funktionen macht es mir sehr Spa\u00df, die Vokabeln dann schlussendlich zu lernen. Das Abschreiben ist meistens etwas m\u00fchsam, das mache ich aber am iPad mit der Tastatur.\nAu\u00dferdem ist die Funktion, dass man die Vokabeln automatisch \u00fcbersetzen lassen kann, sehr n\u00fctzlich. Trotzdem kann man auch die Vokabeln \u201enormal\u201c abtippen und dadurch schon gleich lernen.\nAlso alles in allem: sehr empfehlenswert und definitiv wert, komplett kostenlos herunterzuladen und zu nutzen.\nVielen Dank an die Entwickler!!\nLeider funktioniert die iCloud- Synchronisation bei mir nicht (mehr)... Bitte beheben :) Dankesch\u00f6n!",
        "stars": "5",
        "date": "2020-11-02T17:03:20"
    },
    {
        "author": "fischkopfhaha",
        "title": "Tolle App",
        "review": "Ich nutze die app erst seit kurzem aber bin schon sehr zufrieden mit ihr, sie ist gut geeignet zum unterwegs lernen und ich muss sagen es macht mir sogar spa\u00df mit der app zu lernen. Zusammenfassend die app ist einfach nur toll und empfehlenswert.",
        "stars": "5",
        "date": "2020-10-20T15:21:25"
    },
    {
        "author": "VivaxD",
        "title": "Super!",
        "review": "Eine bessere App kann es meiner Meinung nach nicht geben. Klasse !",
        "stars": "5",
        "date": "2020-10-08T20:58:52"
    },
    {
        "author": "weebtz",
        "title": "Super App:)",
        "review": "Jetzt ist lernen weniger nervig und man kann auch schnell unterwegs, oder im Bus lernen, schreibe seitdem nur noch gute Noten!^^",
        "stars": "5",
        "date": "2020-09-20T11:09:48"
    },
    {
        "author": "Retrixer",
        "title": "Sehr gut!",
        "review": "Diese App erf\u00fcllt alle Anforderungen in vollen Z\u00fcgen und verlangt keine \u00fcberzogenen Preise f\u00fcr zus\u00e4tzliche Funktionen. \nF\u00fcr einmalig 4 Euro voller Funktionsumfang.\nKann Bewertungen unterhalb von 4 /5 Sternen nicht nachvollziehen.",
        "stars": "5",
        "date": "2020-09-07T09:04:32"
    },
    {
        "author": "Bonzei_1974",
        "title": "Beispiellos",
        "review": "Da hat aber jemand ein gutes H\u00e4ndchen.\nEine verdammt gut durchdachte/umgesetzte App \ud83d\udc4d\ud83c\udfff\ud83d\udc4d\ud83c\udfff\ud83d\udc4d\ud83c\udfff\ud83d\udc4d\ud83c\udfff\ud83d\udc4d\ud83c\udfff\ud83d\udc4d\ud83c\udfff\ud83d\udc4d\ud83c\udfff\ud83d\udc4d\ud83c\udfff\ud83d\udc4d\ud83c\udfff\ud83d\udc4d\ud83c\udfff\ud83d\udc4d\ud83c\udfff\nKein Abo, fast schon l\u00e4cherlich g\u00fcnstig \ud83d\ude0f",
        "stars": "5",
        "date": "2020-08-25T18:29:48"
    },
    {
        "author": "olivia12333",
        "title": "Super",
        "review": "Benutze die App f\u00fcr Franz\u00f6sisch und Englisch. Komme damit gut klar!\nLg\ud83d\ude0a",
        "stars": "5",
        "date": "2020-08-16T19:53:24"
    },
    {
        "author": "WickingerFilm",
        "title": "Perfekt f\u00fcr Fremdsprachen",
        "review": "Ich bin wirklich begeistert von der simplen Men\u00fcf\u00fchrung, der automatischen \u00dcbersetzung (und das Vorlesen!), der Integration der Mac-Tastaturbefehle mit externer Tastatur am iPad und den \u00dcbungs-/Pr\u00fcfungsmodi. Kleiner Wunsch an die Entwickelnden f\u00fcr k\u00fcnftige Updates: Die M\u00f6glichkeit, selbstst\u00e4ndig die Schriftgr\u00f6\u00dfe der Karteikarten anzupassen w\u00e4re fantastisch (gerade bei Sprachen, die nicht das lateinische Alphabet verwenden und filigraner sind w\u00e4re das noch praktischer!) Ansonsten vielen Dank f\u00fcr diese tolle Erg\u00e4nzung zum Lernen \ud83d\ude01",
        "stars": "5",
        "date": "2020-07-24T20:19:21"
    },
    {
        "author": "Troniker7",
        "title": "Automatisch \u00fcbersetzen geht wieder",
        "review": "F\u00fcr mich war die Funktion 'Automatisch \u00fcbersetzen' ausschlaggebend. Erstmal funktionierte diese \u00fcberhaupt nicht, jetzt wurde das Problem behoben.",
        "stars": "5",
        "date": "2020-07-14T03:41:04"
    },
    {
        "author": "Fie302",
        "title": "Super!",
        "review": "Einfach zu bedienen, schnell die Karten auch am PC erstellt und super praktisch!\nGenial f\u00fcr unterwegs, kein Stapel geschleppe mehr und f\u00fcr wenig Geld kann man sich einige n\u00fctzliche Funktionen dazu holen! Das Geld daf\u00fcr hat sich definitiv gelohnt!!\nIch bin von der App begeistert und kann es nur weiterempfehlen, wirklich gute Arbeit :)",
        "stars": "5",
        "date": "2020-04-24T10:06:40"
    },
    {
        "author": "Fenycks",
        "title": "Klasse!",
        "review": "Ich bin froh, dass ich diese App gefunden habe. Alle anderen Apps sind kompliziert aufgebaut und mindern den Lernspa\u00df. Habe mir gleich das Komplettset gekauft!",
        "stars": "5",
        "date": "2020-04-10T16:29:05"
    },
    {
        "author": "msnd xiem",
        "title": "Guter Einstieg",
        "review": "Die App ist sch\u00f6n \u00fcbersichtlich und funktioniert soweit ganz gut, was mir pers\u00f6nlich noch fehlt:\nIndividuelle Ordnerstruktur; schriftgr\u00f6\u00dfe, -Farbe, ...\nFunktionierende ICloud synchronisierung",
        "stars": "4",
        "date": "2020-04-01T13:11:36"
    },
    {
        "author": "siegeslied",
        "title": "Gut",
        "review": "Habe schon einiges an Vokabeltrainern getestet - und gel\u00f6scht. Diese hat  Bestand. Danke f\u00fcr kostenlos und nicht irgendwo registrieren m\u00fcssen \ud83d\udc4d\ud83c\udffc Hab es jetzt tats\u00e4chlich doch mal gekauft - und habe es - bislang - noch nicht bereut. Weiter so.",
        "stars": "4",
        "date": "2020-03-31T16:01:43"
    },
    {
        "author": "7408647",
        "title": "Gut gelungen",
        "review": "Gute App welche sehr \u00fcbersichtlich gestaltet ist. Am besten gef\u00e4llt mir jedoch die iCloud Synchronisation, so dass man auf allen Ger\u00e4ten die Vokabeln lernen kann. Was noch sch\u00f6n w\u00e4re, wenn es die App auch f\u00fcr Macs gibt, so dass man auch dort seine Vokalen lernen k\u00f6nnte.",
        "stars": "4",
        "date": "2020-03-26T11:31:56"
    },
    {
        "author": "Sandoo_83",
        "title": "Einfach und \u00fcbersichtlich",
        "review": "Beim Lernen sollte man sich auf das Wesentliche konzentrieren k\u00f6nnen. \nDer Aufbau und die Benutzeroberfl\u00e4che dieser App l\u00e4sst dies perfekt zu. Schnell hat man unz\u00e4hlige Lernkarten geschrieben. So pr\u00e4gt man sich den zu lernenden Stoff schon beim Erstellen der Karten ein und kann danach mit einem guten System (Ebenensystem) lernen. \nVielen Dank f\u00fcr diese einfache und \u00fcbersichtliche App, die genau das macht was man erwartet!",
        "stars": "5",
        "date": "2020-03-20T12:55:06"
    },
    {
        "author": "Michi X",
        "title": "Tolle App",
        "review": "Super zum Lernen, sehr gut durchdacht. \nMir macht es Spa\u00df damit zu lernen.",
        "stars": "5",
        "date": "2020-03-17T22:20:37"
    },
    {
        "author": "Lion Stricker",
        "title": "Hab es gerade heruntergeladen und bis jetzt scheint alles in Ordnung zu sein.",
        "review": "Auch kostenlos, also denke ich mal\ud83d\ude02\ud83e\udd37\u200d\u2642\ufe0f",
        "stars": "5",
        "date": "2020-03-01T11:36:15"
    },
    {
        "author": "Clara Zai",
        "title": "Hervorragend",
        "review": "Ich habe die App schon l\u00e4ngere Zeit und nutze sie sehr gern. Sie \u00fcbersichtlich und gut zu handhaben. Gerade wenn man in einem fremden Land unterwegs ist und schnell eine neue Vokabel notieren will, ist die App sehr praktisch. Das Handy hat man schlie\u00dflich immer parat, im Gegensatz zum Vokabelheft.",
        "stars": "5",
        "date": "2020-01-21T16:30:56"
    },
    {
        "author": "Prof_crazy",
        "title": "Gute Arbeit muss gelobt werden!",
        "review": "Bin mega zufrieden mit dieser App. Kann ich nur von Herzen Weiterempfehlen. Benutze sie t\u00e4glich im Studium und sie hat mir immer gute Dienste geleistet. Wenn man Bugs kamen, wie z.B das der Darkmode am Anfang rumgespackt hat, wurde das immer schnell gel\u00f6st. Auch die Import Funktion \u00fcber TXT. Formate funktioniert \u00fcberraschend gut. Gerade gut 70 Karten auf einmal erstellt ohne Probleme. Cool w\u00e4re noch eine Applikation f\u00fcr MAc aber das ist schon Meckern auf hohenn Niveau. Fazit: sie erf\u00fcllt ihren Job perfekt.",
        "stars": "5",
        "date": "2020-01-15T21:12:45"
    },
    {
        "author": "Germike",
        "title": "Nett gemacht und praktisch",
        "review": "Genau das, was ich brauchte f\u00fcr meine Pr\u00fcfung. Vokabeln brauche ich nicht aber die Karten sind super. Einfache textbasierte Schnittstelle um alles leicht zu importieren (und exportieren zur Sicherung). Gro\u00dfes Lob.\nUpdate im Juli 2019: Noch immer ein n\u00fctzliches und geradezu liebevoll gestaltetes und gepflegtes Programm. Dem Autor f\u00e4llt auch immer etwas n\u00fctzliches Neues ein. Ich kann es noch immer uneingeschr\u00e4nkt empfehlen!",
        "stars": "5",
        "date": "2019-12-27T05:01:52"
    },
    {
        "author": "JCM1831",
        "title": "Sehr gute App zum Lernen nur eines fehlt hier",
        "review": "Benutze die App t\u00e4glich zum Lernen neuer Vokabeln, Fachbegriffe oder Definitionen. Die Integration von Bildern ist ein sehr gutes extra Feature, welches ich sehr h\u00e4ufig verwende!\nIch kann Lernbox weiter empfehlen! Auch die Upgrades f\u00fcr beispielsweise die Integration von Bildern lohnen sich und sind daf\u00fcr g\u00fcnstig. \nAber eine Sache fehlt um die App 100% perfekt zu machen: die M\u00f6glichkeit handschriftliche Lernkarten zu erstellen mit dem Apple Pencil. Ich habe das bisher bei keiner anderen App gesehen! Leider \nDaf\u00fcr vier Sterne mit der Hoffnung auf die Integration der Handschrift! Dann werden es 5 Sterne!",
        "stars": "4",
        "date": "2019-12-18T18:47:53"
    },
    {
        "author": "tiraex",
        "title": "Sehr zufrieden !",
        "review": "Nutze die App zum lernen von Vokabeln und fachbezogenen Informationen. Die verschiedenen M\u00f6glichkeiten und Tests helfen mir sehr gut. Bin sehr zufrieden",
        "stars": "5",
        "date": "2019-12-15T14:49:43"
    },
    {
        "author": "Tivan S.",
        "title": "Perfekt",
        "review": "Eine super App welche ich zum Chinesisch lernen benutze. Sie enth\u00e4lt keine Werbung obwohl sie kostenlos ist. Habe mir sogar irgendwann die komplett Version geholt obwohl ich sie garnicht brauch einfach um den Entwickler zu unterst\u00fctzen. Vielen Dank f\u00fcr diese App!",
        "stars": "5",
        "date": "2019-12-09T08:17:23"
    },
    {
        "author": "Millionen gut",
        "title": "Echt gut!!!",
        "review": "Ich besitze diese App inzwischen schon seit 1-2 Jahren und kenne keine bessere! Ist wirklich zu empfehlen!",
        "stars": "5",
        "date": "2019-12-05T16:39:28"
    },
    {
        "author": "anspan321",
        "title": "Updatefehler schnell behoben",
        "review": "Ich bin mit dieser App sehr zufrieden. Vokabeln k\u00f6nnen schnell und auch unterwegs gelernt werden. \nAuch die schnelle Hilfe des Entwicklers nach einem fehlerhaften Update und die Behebung dieses hat mich begeistert! \nRundum ist die App nur zu empfehlen!",
        "stars": "5",
        "date": "2019-11-25T06:26:08"
    },
    {
        "author": "Scully1110",
        "title": "Hervorragender Kundenservice",
        "review": "Ich bin tats\u00e4chlich \u00fcberw\u00e4ltigt wie schnell das Problem behoben worden ist. Ich hatte Freitag festgestellt, dass nach dem letzten Update keine Tests mehr durchzuf\u00fchren waren. Hatte das Problem mittels Email und Rezension geschildert und postwendend Antwort erhalten. Selbst das Wochenende schien kein Problem. Ich schreibe tats\u00e4chlich so gut wie nie, Rezensionen, aber die Entwickler dieser App, haben ein gro\u00dfes Lob und Dankesch\u00f6n verdient. Fehler passieren schnell, wie man damit umgeht und eine Probleml\u00f6sung ausarbeitet, eine andere. Danke daf\u00fcr! F\u00fcnf Sterne plus",
        "stars": "5",
        "date": "2019-11-24T21:15:12"
    },
    {
        "author": "moofficiel1",
        "title": "Sehr gut f\u00fcr meine Gesellen Pr\u00fcfung",
        "review": "\ud83d\udc4d\ud83c\udfff\ud83d\udc8a",
        "stars": "5",
        "date": "2019-11-23T07:38:46"
    },
    {
        "author": "Erkan5282",
        "title": "Einfach Wow",
        "review": "Alleine schon das man die Lernkartei teilen kann was man erstellt hat, ist 6 Sterne wert. Super App dazu kostenlos.",
        "stars": "5",
        "date": "2019-11-22T11:40:52"
    },
    {
        "author": "sohligsc",
        "title": "Extrem n\u00fctzlich",
        "review": "Perfektes Tool um schnell Karteikarten zu erstellen und jederzeit lernen zu k\u00f6nnen.",
        "stars": "5",
        "date": "2019-11-21T22:52:49"
    },
    {
        "author": "Nespi3737",
        "title": "Top App zum lernen",
        "review": "Die App ist super und das nicht nur f\u00fcr Vokabeln lernen.",
        "stars": "5",
        "date": "2019-11-21T19:19:32"
    },
    {
        "author": "Wuantu",
        "title": "Super App",
        "review": "Gutes App zum lernen von Vokabeln und Karteikarten. Einfach und sauber aufgebaut. Mit Importfunktion.",
        "stars": "5",
        "date": "2019-11-21T01:00:11"
    },
    {
        "author": "gl2r",
        "title": "Wirklich klasse",
        "review": "Einfach leichter zum lernen f\u00fcr unterwegs",
        "stars": "5",
        "date": "2019-11-20T21:35:51"
    },
    {
        "author": "janosaniol",
        "title": "5/5",
        "review": "Einfach nur n\u00fctzlich diese App.\nAm Vorabend eines Vokabeltests mit 60 Vokabeln eine halbe bis Dreiviertel Stunde mit der App \u00fcben und man kann sich sicher sein, dass man alle auswendig kann.\n\ud83d\udc4d\ud83c\udffb\ud83d\udc4d\ud83c\udffb",
        "stars": "5",
        "date": "2019-11-18T16:21:00"
    },
    {
        "author": "thomys2105",
        "title": "Top",
        "review": "Danke f\u00fcr diese App. F\u00fcr mich perfekt.......\ud83d\udc4d",
        "stars": "4",
        "date": "2019-11-13T11:44:16"
    },
    {
        "author": "lyk01",
        "title": "Perfekt zum \ud83c\uddf8\ud83c\uddea Schwedisch-Lernen",
        "review": "Bin begeistert! Auch Schwedisch ist mit Aussprache und \u00dcbersetzung dabei - so macht das Eingeben neuer Vokabeln (und sogar ganzer S\u00e4tze) noch viel mehr Spa\u00df.",
        "stars": "5",
        "date": "2019-11-13T06:29:53"
    },
    {
        "author": "So schade",
        "title": "T\u00e4glich im Einsatz",
        "review": "F\u00fcr uns schlicht die allerbeste App zum Vokabellernen!",
        "stars": "5",
        "date": "2019-10-27T12:55:02"
    },
    {
        "author": "katjala",
        "title": "Klasse App die keine W\u00fcnsche offen l\u00e4\u00dft",
        "review": "Die App bietet viele Einstellungsm\u00f6glichkeiten, um das Lernen so anzupassen, wie es f\u00fcr einen selbst perfekt ist.\nIch nutze z. B. Wartezeiten gerne um eine Trainingseinheit zu absolvieren.",
        "stars": "5",
        "date": "2019-10-25T07:15:39"
    },
    {
        "author": "maryloo98",
        "title": "Super App! Einfach und \u00fcbersichtlich!",
        "review": "Es geht einfach, die Einstellungen die man braucht sind alle da. Nur eine Website an der man auch am Laptop lernen k\u00f6nnte w\u00e4re super! \nAnsonsten wirklich gelungen und vielen Dank!",
        "stars": "4",
        "date": "2019-10-07T09:56:44"
    },
    {
        "author": "T_G\u00fclay",
        "title": "Sehr gut",
        "review": "Die App ist wirklich toll. Seit ich die habe, habe ich immer in Vokabeltests eine 1+. Das einzige schlechte ist, dass man die Vokabel nicht lesen kann, wenn es zu lang ist. Trotzdem 5 Sterne\u263a\ufe0f. \nIhr seid meine Helden",
        "stars": "5",
        "date": "2019-10-06T16:09:16"
    },
    {
        "author": "Rowa104",
        "title": "Idealer Vokabeltrainer f\u00fcr unterwegs",
        "review": "Eigene Vokabeln eingeben und \u00fcberall lernen!! Super.",
        "stars": "5",
        "date": "2019-10-03T14:18:22"
    },
    {
        "author": "Eierkarton1234",
        "title": "Genial!",
        "review": "Hab mir innerhalb eines Tages 30 Franz\u00f6sisch-Vokabeln drauf geschafft. Und jede Menge Facts zu anderen F\u00e4chern!\nMit dem Ding k\u00f6nnten Lehrer gut arbeiten indem die den Sch\u00fclern Karteikarten schicken, die die Sch\u00fcler dann lernen m\u00fcssen...\nDie Statistik finde ich total motivierend! W\u00e4re toll wenn da mehr gingen, Monatsatatistik und so.\nAuch die Zusatzfunktionen sind mehr als diese 3 Euro wert! Geradezu albern billig, wenn man bedenkt was materielle Karteikarten kosten!\nDas Einzige was nervt ist, dass das Ding so komisch spricht, wenn man bei den Karteikarten da drauf kommt. \ud83d\ude1c\nIch kann im Bus lernen...im Park...Abends bei einem Bier in der Kneipe...genial!\nBastel mal einen Spendenlink f\u00fcr Paypal in die App und schreib dabei: Eine milde Gabe f\u00fcr jede 1! \ud83d\ude02 Verdienst sicher mehr als an diesem albernen 3,50. \ud83d\ude1c",
        "stars": "5",
        "date": "2019-09-28T18:43:04"
    },
    {
        "author": "chawer0",
        "title": "sehr empfehlenswert!",
        "review": "Vielseitig einsetzbar, gut gestaltet und individuell anpassbar.",
        "stars": "5",
        "date": "2019-09-23T20:16:13"
    },
    {
        "author": "hkgftv",
        "title": "Cool",
        "review": "Wenn mal einer verstehen wie sie funktioniert ist echt klasse",
        "stars": "5",
        "date": "2019-09-02T14:38:11"
    },
    {
        "author": "NUtZEr620tt",
        "title": "Sehr Gut. Synchronisation funktioniert leider nicht",
        "review": "An sich ist die App ziemlich gut. Das Design  ist gut durchdacht und alles ist sch\u00f6n geordnet. Leider sind mir bei dem Versuch die Vokabeln mit einem anderen Ger\u00e4t zu synchronisieren, \u00fcber 1000 Vokabelkarten verloren gegangen, was viel Arbeit war. Das ist auch der einzige Verbesserungsvorschlag, den ich f\u00fcr die App h\u00e4tte. Aber daran wird anscheinend bereits gearbeitet.",
        "stars": "4",
        "date": "2019-08-29T13:43:26"
    },
    {
        "author": "Snaefjell",
        "title": "Vorz\u00fcglich",
        "review": "Das ist ein hervorragendes Karteiensystem. Der Vokabeltrainer ist super. Es gibt sicherlich noch Kleinigkeiten zu verbessern, aber auf diese App kann man aufbauen!",
        "stars": "5",
        "date": "2019-07-29T16:25:57"
    },
    {
        "author": "Bonzo Schmitchen",
        "title": "Lernen bei jeder Gelegenheit",
        "review": "Toll ist vor Allem, dass man Bilder in die Karteikarten einf\u00fchren kann. Den Export der Karteikarten habe ich zwar noch nicht ausprobiert, ist aber eine sch\u00f6ne Funktion.",
        "stars": "5",
        "date": "2019-07-13T11:48:22"
    },
    {
        "author": "Ewolin",
        "title": "Super App",
        "review": "Super App hilft mir sehr beim lernen.\nZuf\u00e4llige Fragenreihenfolge w\u00e4re noch toll wenn es kommt.",
        "stars": "4",
        "date": "2019-07-12T20:10:06"
    },
    {
        "author": "\u0160ej",
        "title": "Sehr gute App",
        "review": "Sehr,sehr gut(die App kann ich nur empfehlen!)\ud83d\ude0a\ud83d\udc4d\ud83c\udffd",
        "stars": "4",
        "date": "2019-07-07T21:29:18"
    },
    {
        "author": "alwesin",
        "title": "Super!",
        "review": "Ich finde die App einfach Klasse!\nVielen Dank an den Entwickler!!!",
        "stars": "5",
        "date": "2019-06-26T20:39:28"
    },
    {
        "author": "Pete928",
        "title": "Toll zum Lernen",
        "review": "Hurra, endlich funktioniert auch das Einsortieren in Ebenen bei Tests.\nNachdem dieser Fehler behoben ist, handelt es sich um eine tolle App, die eigentlich alles bietet, was man sich von einer Lernkarten-App erwartet und man mit den klassischen Karten auf Papier auch tut.\nBesonders praktisch ist die (Add-on-)Funktion zum Einbinden von Grafiken. Was man allerdings z.B. f\u00fcr mathematische Formeln bis zu einem gewissen Grad gar nicht ben\u00f6tigt, hier kommt man durch die konsequente Nutzung von Unicode-Zeichen recht weit, ebenso f\u00fcr phonetische Symbole. Wer es hierf\u00fcr nutzen will, f\u00fcr den ist also eine gute Unicode-Tastatur eine ideale Begleitung.",
        "stars": "5",
        "date": "2019-06-23T15:25:59"
    },
    {
        "author": "Annaleniiii",
        "title": "SUPER GENIAL ZUM LERNEN \ud83c\udf8a",
        "review": "Die App ist wirklich super selbst ohne die Optionen, die man kaufen kann. \nIch w\u00fcrde mir nur w\u00fcnschen, dass man auch nur Karten zum lernen erstellen k\u00f6nnte. Also ohne abgefragt zu werden. \nAlso wie lernk\u00e4rtchen\ud83d\ude48 \nAber sonst bin ich sehr zufrieden und bin froh die App gefunden zu haben!",
        "stars": "5",
        "date": "2019-06-11T19:35:43"
    },
    {
        "author": "igelmac",
        "title": "Begeistert",
        "review": "Wir fangen gerade damit an mit der Lernbox Vokabeln (D\u00e4nisch) zu lernen und sind begeistert:\n- Beim \u201eBef\u00fcllen\u201c der Vokabeln ein d\u00e4nisches Wort eingeben, die deutsche \u00dcbersetzung ist gleich da.\n-Aussprache anh\u00f6ren, nur eins von 90 Worten wurde nicht korrekt ausgesprochen.\n- Austausch der Vokabeln mit zweitem iPad. Alle werden per AirDrop \u00fcbertragen, aber die neuen zum Hinzuf\u00fcgen angezeigt.\n- in App Kauf empfehlenswert, schon allein weil g\u00fcnstig und damit wenigstens ein kleines Dankesch\u00f6n an den Entwickler m\u00f6glich ist.\nMal wieder eine App die mich positiv \u00fcberrascht!",
        "stars": "5",
        "date": "2019-06-02T10:50:50"
    },
    {
        "author": "Laurin Ober",
        "title": "Beste",
        "review": "Beste Lernapp",
        "stars": "5",
        "date": "2019-06-02T07:01:44"
    },
    {
        "author": "Marmeladenbrot5",
        "title": "Perfekt f\u00fcr Vokabeln",
        "review": "Hab inzwischen 2000 Vokabeln oder so in der App. B\u00e4sta appen n\u00e5nsin!",
        "stars": "5",
        "date": "2019-05-30T09:09:27"
    },
    {
        "author": "Rotfrosch",
        "title": "Beste App",
        "review": "Um mit eigenen Karten und v\u00f6llig unabh\u00e4ngig zu lernen, ist das die beste App f\u00fcr mich. Ich habe in einer neuen Firma angefangen und mit dieser App die gesamte Produktpalette erlernt.\nVon mir w\u00fcrde es *********** mehr Sterne geben!!!",
        "stars": "5",
        "date": "2019-05-27T17:35:22"
    },
    {
        "author": "HolzYogi",
        "title": "Sehr gute App",
        "review": "Die App l\u00e4sst sich ab der 5. Klasse gut einsetzen. Verschiedene Modi in der Lernphase festigen das Wissen. Der Testmodus arbeitet nach einer anerkannten Lernmethode. Besonders gut: Vokabeln werden auch vorgesprochen. Der In-App-Kauf ist sein Geld wert. Der Entwickler ist sehr freundlich und reagiert auf Fragen prompt und kompetent.",
        "stars": "5",
        "date": "2019-05-01T17:25:51"
    },
    {
        "author": "Kkhggkncydhkl\u00f6cc",
        "title": "\ud83d\udc4d\ud83c\udffb",
        "review": "Sehr gut zum Vokabeln lernen",
        "stars": "5",
        "date": "2019-03-31T10:52:18"
    },
    {
        "author": "duiddavid",
        "title": "Der kleine Preis...",
        "review": "...f\u00fcr die Vollversion steht in keinem Verh\u00e4ltnis zum Nutzen. Fragenkataloge sind am PC \u00fcber txt.-Dateien einfach zu erstellen und zu editieren, lassen sich in der App weiter anpassen. Hat mir sehr bei meiner IMPP-Pr\u00fcfung geholfen und empfehle sie flei\u00dfig weiter!",
        "stars": "5",
        "date": "2019-03-28T12:42:41"
    },
    {
        "author": "MacA10",
        "title": "Ich bin begeistert",
        "review": "Ich bin auf die App aufmerksam geworden, da ich eine digitale M\u00f6glichkeit zum Vokabeltraining gesucht habe. Diese App ist daf\u00fcr einfach nur perfekt. Es ist das erste Mal, dass ich ernsthaft \u00fcberlege, mir das Paket auch zu kaufen, da mir besonders die Ausspracheoption schon fehlt und mir die App einfach so gut gef\u00e4llt. Ich finde es auch gut, dass ich zwischen verschiedenen Abfragemodi w\u00e4hlen kann und dass ich die App gleichzeitig auch f\u00fcr die Grammatik nutzen kann. Die Bedienung ist \u00fcberaus intuitiv. Ein gro\u00dfes Plus ist au\u00dferdem die Importfunktion - sch\u00fctzt vor Handydaumen ;-)",
        "stars": "5",
        "date": "2019-03-17T08:38:24"
    },
    {
        "author": "fabian_9106",
        "title": "Super App!",
        "review": "Hier hat sich jemand Gedanken gemacht",
        "stars": "5",
        "date": "2019-03-13T19:46:18"
    },
    {
        "author": "Wastil",
        "title": "\ud83d\udc4d\ud83c\udffb",
        "review": "Gef\u00e4llt mir sehr gut - wird immer besser!",
        "stars": "4",
        "date": "2019-02-28T12:57:18"
    },
    {
        "author": "Dreamer Lena",
        "title": "Eine der besten Lernapps!",
        "review": "Eine wirklich unglaublich tolle M\u00f6glichkeit um Vokabeln zu lernen! Die t\u00e4glichen Tests und \u00dcbungen helfen super und zeigen einen, wie gut man schon ist. Auch toll finde ich, dass man seine Fehler in einer weiteren \u00dcbung nochmal lernen kann. Ich empfehle die App allen, weil sie mir wirklich geholfen hat, auch bei Tests die um andere F\u00e4cher, als Englisch, Spanisch usw. gingen.\nDanke f\u00fcr diese tolle M\u00f6glichkeit!",
        "stars": "5",
        "date": "2019-02-28T05:21:52"
    },
    {
        "author": "_TheHolygamer_",
        "title": "Geil",
        "review": "Richtig geil, man muss es haben um richtig zu lernen.",
        "stars": "5",
        "date": "2019-02-21T17:54:26"
    },
    {
        "author": "Klausistraus",
        "title": "Super Lernkarten App",
        "review": "Die App funktioniert sehr gut und erf\u00fcllt prima ihren Zweck. Kleine Verbesserungen und Anpassung sind wohl noch zu machen, aber der Entwickler reagiert sehr schnell und ist bem\u00fcht zu helfen. Das ist ein toller Service wenn man seine Kundenstimmen so ernst nimmt. \nIch w\u00fcrde mir w\u00fcnschen das beim einf\u00fcgen von Bildern diese auch alleine stehen k\u00f6nnten und etwas gr\u00f6\u00dfer sind. Da ich sie zum Lernen von Objekten, Personen und Designgegenst\u00e4nden nutze, ist es f\u00fcr mich zeitweise Wichtig das Bild ohne zus\u00e4tzliche Frage zu haben. \nSonst aber eine sehr hilfreiche App die das Lernen erleichtert.",
        "stars": "5",
        "date": "2019-02-20T17:54:09"
    },
    {
        "author": "tho-me",
        "title": "gelungen",
        "review": "Eine sehr umfangreiche lern-App. Man gibt Frage-und-Antwort-Spiel Antwort ein und l\u00e4sst sich dann sinnvoll Abfragen. Die App bietet einen ordentlichen Rahmen, die Inhalte und deren Qualit\u00e4t liefert man selbst. \nHalt wie im physischen Leben auch. \nIch bin begeistert.",
        "stars": "5",
        "date": "2019-02-12T08:25:35"
    },
    {
        "author": "j.nd",
        "title": "Wirklich gut!",
        "review": "Finde die App wirklich sehr gut zum lernen!\nKeine Werbung und die wenigsten Sachen muss man bezahlen.\nEmpfehle die App weiter!",
        "stars": "5",
        "date": "2019-02-05T19:37:12"
    },
    {
        "author": "Ronkor",
        "title": "Sehr Gut!",
        "review": "Die App ist sehr gut. \nWas mir noch fehlt w\u00e4hre eine Passende App f\u00fcr den Mac die es noch einfacher und schneller machen w\u00fcrde gro\u00dfe Mengen an Karten zu erstellen. \nWie beim Vorg\u00e4nger w\u00fcrde ich mich auch \u00fcber eine Anzeige der l\u00e4ngeren antworten freuen. \nSonst ist die App wirklich schon sehr sehr gut!",
        "stars": "5",
        "date": "2019-01-29T18:34:31"
    },
    {
        "author": "Dilem Celik",
        "title": "Super",
        "review": "Es ist super zum \u00fcben kann ich nur weiter empfehlen",
        "stars": "5",
        "date": "2019-01-12T09:13:39"
    }
];
