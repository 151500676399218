import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Heading from "../components/Heading";
import styles from "./NotFoundPage.module.css";
import {NavLink} from "react-router-dom";

export default function NotFoundPage() {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <title>{t("Learnbox")} - {t("PageNotFound")}</title>
      </Helmet>
      <NavLink className={styles.homeButton} exact to="/">
        &#10092;{" " + t("Home")}
      </NavLink>
      <article>
        <Heading text={t("PageNotFound")} />
        <p>{t("PageNotFoundContent")}</p>
      </article>
    </Fragment>
  );
}
